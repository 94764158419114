.world-cloud-chart {
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
}

.world-cloud-chart h5 {
  font-weight: 700;
  padding-top: 30px;
  color:#555;
}
